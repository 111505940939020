import styled from 'styled-components';

import Icon from 'components/atoms/Icon';
import Typography from 'components/atoms/Typography';

export const StyledLabel = styled.label`
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  padding-bottom: var(--gap-xxxs);
  margin-bottom: var(--gap-xl);
  color: var(--c-white);
  border-bottom: 1px solid var(--c-gray-400);

  &[data-type='checkbox'] {
    flex-direction: row;
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
  }

  &[data-type='phone'] {
    padding-bottom: var(--gap-xxxxxs);
  }

  &[data-variant='careers'] {
    input {
      color: var(--c-gray-500);
    }
  }

  .link {
    text-decoration: underline;
  }
`;

export const StyledSpanCheckbox = styled.span`
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  margin-right: var(--gap-xxxs);
  cursor: pointer;
  background: var(--c-white);
  border-radius: var(--border-radius);

  &[data-variant='careers'] {
    background: var(--c-gray-800);
  }
`;

export const StyledInput = styled.input`
  &[data-type='phone'] {
    width: 100%;
  }

  &[data-type='checkbox'],
  &[data-variant='careers'] {
    &:checked + span::before {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      content: url('/images/checkbox-icon.svg');
    }

    &:focus {
      outline: none;
    }
  }

  &[data-variant='careers'] {
    &:checked + span::before {
      content: url('/images/checkbox-icon-white.svg');
    }
  }

  &[data-type='checkbox']:focus + ${StyledSpanCheckbox} {
    outline: 2px solid var(--focus-color);
    outline-offset: 2px;
  }
`;

export const StyledPhoneInput = styled.div`
  display: flex;
`;

export const StyledError = styled(Typography)`
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateY(100%);
`;

export const StyledIcon = styled(Icon)`
  position: absolute;
  right: 0;
  bottom: 0;
  margin-bottom: var(--gap-xxxs);
`;
