import React, { FC, useEffect, useRef, useState } from 'react';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { INLINES } from '@contentful/rich-text-types';

import {
  StyledError,
  StyledIcon,
  StyledInput,
  StyledLabel,
  StyledPhoneInput,
  StyledSpanCheckbox,
} from 'components/atoms/Input/Input.styles';
import Typography from 'components/atoms/Typography';
import CountryCode from 'components/molecules/CountryCode';
import { FormSectionVariant } from 'components/molecules/FormSection';

import { InputProps } from './models.d';

const options = {
  renderNode: {
    [INLINES.ENTRY_HYPERLINK]: (_, children) => (
      <a className="link" data-target="hover" href="/privacy-policy">
        {children}
      </a>
    ),
  },
};

const Input: FC<InputProps> = ({
  name,
  checkboxText,
  type,
  register,
  error,
  formVariant,
  placeholder,
  isSubmited,
  setIsSubmited,
}) => {
  const { CAREERS } = FormSectionVariant;
  const isCareersVariant = formVariant === CAREERS;
  const isCheckbox = type === 'checkbox';
  const defaultCountryCode = '+48';
  const phoneInputRef = useRef<HTMLInputElement | null>(null);
  const [countryCode, setCountryCode] = useState(defaultCountryCode);
  const [mixedValue, setMixedValue] = useState(`${defaultCountryCode} `);

  useEffect(() => {
    if (isSubmited) {
      setMixedValue(`${countryCode} `);
      setIsSubmited(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmited, setIsSubmited]);

  const changeHandler = (e) => {
    const { value } = e.target;
    if (value.startsWith(`${countryCode} `)) {
      setMixedValue(value);
    }
  };

  const patternPrevent = (pattern) => (event) => {
    if (!pattern.test(event.key)) {
      event.preventDefault();
    }
  };

  const patternRegExp = new RegExp('[0-9]' || '');

  const { ref, ...rest } = register(type, { onChange: changeHandler });

  const renderInput = (inputType) => {
    switch (inputType) {
      case 'phone':
        return (
          <StyledPhoneInput>
            <CountryCode
              variant={formVariant}
              phoneInputRef={phoneInputRef}
              onSelect={(e) => {
                setCountryCode(e);
                setMixedValue(`${e} `);
              }}
            />
            <StyledInput
              id={type}
              value={mixedValue}
              maxLength={20}
              onKeyPress={patternPrevent(patternRegExp)}
              data-type={type}
              data-target="hover"
              type={type}
              ref={(e) => {
                ref(e);
                phoneInputRef.current = e;
              }}
              {...rest}
            />
          </StyledPhoneInput>
        );
      case 'checkbox':
        return (
          <>
            <StyledInput
              id={type}
              data-type={type}
              data-variant={formVariant}
              type={type}
              {...register(type)}
            />
            <StyledSpanCheckbox data-variant={formVariant} data-target="hover" />
          </>
        );
      default:
        return (
          <StyledInput
            id={type}
            placeholder={placeholder}
            {...register(type)}
            data-target="hover"
          />
        );
    }
  };

  const getTitleColor = () => {
    if (isCareersVariant) return 'gray-900';
    if (isCheckbox) return 'gray-25';

    return 'white';
  };

  return (
    <StyledLabel data-type={type} data-variant={formVariant} htmlFor={type}>
      {renderInput(type)}
      <Typography
        size={isCheckbox ? 16 : 14}
        fontWeight={400}
        lineHeight={1.43}
        padding={{ bottom: isCheckbox ? 0 : 16 }}
        color={getTitleColor()}
      >
        {isCheckbox && checkboxText ? renderRichText(checkboxText, options) : name}
      </Typography>

      {error && !isCheckbox ? <StyledIcon name="common-alert" renderComponent /> : null}
      {error ? (
        <StyledError
          padding={{ top: 6 }}
          size={14}
          lineHeight={1.4}
          fontWeight={300}
          color="accent-red-300"
        >
          {error}
        </StyledError>
      ) : null}
    </StyledLabel>
  );
};
export default Input;
