import styled from 'styled-components';

import Icon from 'components/atoms/Icon';

import { FormSectionVariant } from '../FormSection/models.d';

const { CAREERS } = FormSectionVariant;

export const StyledCountrySelect = styled.button`
  color: var(--c-gray-25);
  white-space: nowrap;
  width: 50px;
  background: none;
  border: var(--border-transparent);

  &[data-variant='${CAREERS}'] {
    color: var(--c-gray-900);
  }
`;

export const StyledCountryOption = styled.div`
  width: 100%;
  background-color: var(--c-gray-900);
  color: var(--c-gray-25);
  padding: 0 var(--gap-xxxxs) 0 var(--gap-xxxxxs);
  position: relative;

  &[data-variant='${CAREERS}'] {
    background-color: var(--c-gray-25);
    color: var(--c-gray-900);
  }

  &:hover {
    background-color: var(--swiper-theme-color);
    color: var(--c-white);
  }
`;

export const StyledCountryOptions = styled.div`
  height: 362px;
  position: absolute;
  top: 24px;
  left: 0;
  z-index: var(--z-index-ground);
  overflow-y: scroll;
  width: max-content;
  border: 1px solid var(--c-gray-300);
  border-radius: var(--border-radius-md);
  cursor: default;

  .focused {
    background-color: var(--swiper-theme-color);
    color: var(--c-white);
  }

  .hidden {
    display: none;
  }
`;

export const StyledCountrySelectIcon = styled(Icon)`
  flex-shrink: 0;
  width: var(--gap-xs);
  height: 100%;
  margin-top: auto;
  position: absolute;
  top: 0;
  right: 0;
  pointer-events: none;
`;

export const StyledCountrySelectWrapper = styled.div`
  position: relative;
  width: 50px;
  display: flex;
  margin-right: var(--gap-xxs);

  ${StyledCountrySelectIcon} {
    stroke: var(--c-gray-25);
  }

  &[data-variant='${CAREERS}'] {
    ${StyledCountrySelectIcon} {
      path {
        stroke: var(--c-gray-900);
      }
    }
  }
`;
