import React, { ReactElement, useRef, useState } from 'react';
import classNames from 'classnames';

import { countryCodeData } from './CountryCode.contants';
import {
  StyledCountryOption,
  StyledCountryOptions,
  StyledCountrySelect,
  StyledCountrySelectIcon,
  StyledCountrySelectWrapper,
} from './CountryCode.styles';

import { CountryCodeProps } from './models.d';

const CountryCode = ({ onSelect, variant, phoneInputRef }: CountryCodeProps) => {
  const options: ReactElement[] = [];
  const map = {};
  const ref = useRef<HTMLDivElement | null>(null);
  const itemsRef = useRef<HTMLDivElement[]>([]);
  const [index, setIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [shortName, setShortName] = useState('PL');

  const handleOnClick = (name: string, value: string, idx: number) => {
    setShortName(name);
    setIndex(idx);
    if (phoneInputRef?.current) {
      phoneInputRef.current.focus();
    }
    setIsOpen(false);

    if (onSelect) {
      onSelect(value);
    }
  };

  const handleOnBlur = (event) => {
    if (ref.current && !ref.current.contains(event.relatedTarget)) {
      setIsOpen(false);
      setIsClicked(false);
    }
  };

  const handleButtonClicked = () => {
    setIsOpen(true);
    setIsClicked(true);
  };

  const handleOnKeyUp = (
    name: string,
    code: string,
    idx: number,
    e: React.KeyboardEvent<HTMLDivElement>
  ) => {
    const key = e.key || e.keyCode;

    const isLetter = (key >= 'a' && key <= 'z') || (key >= 65 && key <= 90);

    setIsClicked(false);

    if ([27, 'Escape'].includes(key)) {
      setIsOpen(false);
    }

    if ([13, 'Enter'].includes(key)) {
      handleOnClick(name, code, idx);
    }

    if ([9, 'Tab'].includes(key)) {
      setIndex(idx);
    }

    const foundFirstElementIndex = () =>
      options.findIndex((element) => element.props.children.charAt(0) === e.key.toUpperCase());

    const foundElementCallback = (element, elementIndex) => {
      if (
        options[index]?.props.children.charAt(0) === e.key.toUpperCase() &&
        elementIndex < index
      ) {
        return (
          element.props.children.charAt(0) === e.key.toUpperCase() &&
          options[index]?.props.children !== element.props.children &&
          elementIndex > index
        );
      }

      return (
        element.props.children.charAt(0) === e.key.toUpperCase() &&
        options[index]?.props.children !== element.props.children
      );
    };

    const foundElementIndex = () =>
      options.findIndex((element, elementIndex) => foundElementCallback(element, elementIndex));

    if (isLetter) {
      if (foundElementIndex() !== -1) {
        itemsRef.current[foundElementIndex()]?.focus();
        setIndex(foundElementIndex());
      } else {
        itemsRef.current[foundFirstElementIndex()]?.focus();
        setIndex(foundFirstElementIndex());
      }
    }
  };

  Object.keys(countryCodeData).forEach((c, idx) => {
    if (!map[c]) {
      options.push(
        <StyledCountryOption
          key={c}
          onClick={() => handleOnClick(c, countryCodeData[c].code, idx)}
          data-variant={variant}
          ref={(el: HTMLDivElement) => {
            itemsRef.current[idx] = el;
          }}
          className={classNames(index === idx && 'focused')}
          tabIndex={0}
          onKeyUp={(e) => {
            handleOnKeyUp(c, countryCodeData[c].code, idx, e);
          }}
          role="button"
        >
          {countryCodeData[c].name}
        </StyledCountryOption>
      );
    }
  });

  options.push(
    <StyledCountryOption
      data-variant={variant}
      tabIndex={0}
      key="close"
      ref={(el: HTMLDivElement) => {
        itemsRef.current[options.length - 1] = el;
      }}
      onKeyUp={(e) => {
        if (e.key !== 'c') setIsOpen(false);
      }}
      className={classNames({
        [`focused`]: index === options.length,
        [`hidden`]: isClicked,
      })}
    >
      Close
    </StyledCountryOption>
  );

  return (
    <StyledCountrySelectWrapper data-variant={variant}>
      <StyledCountrySelect
        data-testid="country-code"
        data-variant={variant}
        id="country-code"
        aria-label="Select country code"
        aria-haspopup="listbox"
        aria-expanded={isOpen}
        onBlur={(event) => handleOnBlur(event)}
        onClick={() => handleButtonClicked()}
        type="button"
        data-target="hover"
      >
        {shortName}
      </StyledCountrySelect>
      {isOpen ? (
        <StyledCountryOptions ref={ref} role="listbox" tabIndex={-1}>
          {options}
        </StyledCountryOptions>
      ) : null}
      <StyledCountrySelectIcon name="common-chevron-down" />
    </StyledCountrySelectWrapper>
  );
};
export default CountryCode;
